import React from 'react';

// Components
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Navbar from '../components/Navbar';

// Assets
import ImgURL from '../images/public_benefit_header.jpg';

// Styles
import './public-benefit.styles.scss'

const PublicBenefitPage = ({ data }) => (
        <Layout currentPath="/public-benefit">
            <SEO title="Public Benefit" />
            <div className="public-benefit-wrapper">
                <Navbar />
                <div className="public-benefit">
                    <header className="public-benefit__header">
                        <h1 className="public-benefit__header-heading">
                            Why Transferable is a Public Benefit Corporation
                        </h1>
                        <div className="orange-bottom-border" />
                        <div className="public-benefit-header-photo-wrapper">
                            <img 
                                className="public-benefit-header-photo"
                                src={ImgURL}
                                alt="Photo of graduates throwing caps"
                            />
                        </div>
                    </header>
                    <main className="public-benefit__main">
                        <h3 className="public-benefit__main-heading">
                            Transferable is a Public Benefit Corporation, which means:
                        </h3>

                        <ul className="public-benefit-list">
                            <li className="public-benefit-list__item">
                                <div className="number-circle">
                                    1
                                </div>
                                <span className="list-text">
                                    We have an expanded purpose beyond maximizing share value
                                    to explicitly include general and specific public benefit
                                </span>
                            </li>
                            <li className="public-benefit-list__item">
                                <div className="number-circle purple">
                                    2
                                </div>
                                <span className="list-text">
                                    We are required to consider/balance the impact of our decisions
                                    not only on shareholders but also on our stakeholders
                                </span>
                            </li>
                            <li className="public-benefit-list__item">
                                <div className="number-circle">
                                    3
                                </div>
                                <span className="list-text">
                                    We are required to report on our overall social and 
                                    environmental performance
                                </span>
                            </li>
                        </ul>

                        <div className="public-benefit-video-wrapper">
                            <iframe
                                className="public-benefit-video"
                                src="https://www.youtube.com/embed/0W4mIcDucfs"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>

                        <div className="public-benefit-explainer">
                            <p className="public-benefit-explainer__body">
                                We chose this structure because we wanted to codify,
                                into our legal documents, the importance of working for the
                                benefit of students, community colleges, universities, and
                                the higher education system as a whole. An education
                                technology company is stronger when it includes student
                                success as a core organizing value. Our achievements are
                                inextricably intertwined with the achievements of our
                                customers, and we recognize that by inserting the following
                                language into our incorporating documents:
                            </p>
                            <blockquote className="public-benefit-explainer__quote">
                                “The specific public benefit purpose of the corporation is to build products
                                that support students on their academic journeys, having a positive impact
                                on society through the advancement of educational attainment. We believe that
                                every student should have access to educational opportunities that fit their unique
                                interests, goals, and skillsets. Through our efforts to generate strong student
                                outcomes, we further our vision of a high quality and sustainable system
                                of higher education.”
                            </blockquote>
                        </div>
                    </main>
                </div>
            </div>
        </Layout>
);

export default PublicBenefitPage;


